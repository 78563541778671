import React from "react";

import { Container } from "src/components/Container";
import { PageWrapper } from "src/components/PageWrapper";
import { Typography } from "src/components/Typography";

import * as styles from "./NotFound.module.scss";

export const NotFound: React.FC = () => {
  return (
    <PageWrapper
      topAutoScroll
      title="Страница не найдена"
      headerProps={{ colorChangeable: true, forceShow: true }}
    >
      <Container className={styles.root}>
        <Typography variant="h1">
          <span>Ошибка 404.</span>
          <br />
          То есть такой страницы нет на сайте. Или она есть, просто адрес
          написан не правильно.
          <br />
          Попробуйте поискать через меню на
          <a href="/"> главной</a>
        </Typography>
      </Container>
    </PageWrapper>
  );
};
